.terminalsMapPopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    padding: 10px;
    height: 70vh;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.terminalsMapPointInfoBlock {
    width: 40vw;
    position: absolute;
    top: 0;
    z-index: 3;
    background-color: #FFF;
    border-radius: 10px;
    padding: 0 20px 15px 20px;
}

.terminalsMapPointInfoBlock h3 {
    text-align: center;
}

.mapPointInfoRow p {
    display: inline-block;
    margin: 0;
}

.mapPointInfoRow p:first-child {
    margin-right: 10px;
    font-weight: bold;
}


