.loginSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loginForm {
  display: flex;
  flex-direction: column;
  width: 440px;
  padding: 60px;
}
