.licensePopupBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:  hidden;
    background: rgba(0, 0, 0, 0.5);
}

.licensePopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    padding: 10px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.licensePopupInputContainer {
    width: 90%;
    justify-content: start;
    display: flex;
    flex-direction: column;
}

.licensePopupSaveButton {
    margin-top: 25px;
    margin-left: 15px;
    width: 200px;
}

