.dialogGroupActionContainer {
    gap: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.dialogLinksContainer {
    display: flex;
    gap: 50px;
    border-bottom: 1px solid #B7C2CD;
}

.dialogChangeTableBtn {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;

    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #303741;

    box-sizing: border-box;
    padding-bottom: 5px;
    border-bottom: 2px transparent solid;
}

.dialogChangeTableBtnActive {
    color: #5E8ADF;
    border-bottom-color: #5E8ADF;
}

.dialogForActivateCount {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -16px;
    top: -10px;
    background-color: #5E8ADF;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 800;
}
