.terminalEditPopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    padding: 10px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
}

.terminalEditPopupDataContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.terminalEditPopup h1 {
    text-align: center;
}

.terminalEditPopupButtonContainer {
    justify-content: center;
    align-self: center;
}

.terminalEditPopupInputContainer {
    width: 350px;
    justify-content: start;
    display: flex;
    flex-direction: column;
}

.terminalEditPopupEditDataBlock:not(:first-child) {
    margin-left: 40px;
}

.terminalEditPopupEditDataBlock h1 {
    text-align: center;
}

.terminalEditPopupListDataBlock {
    width: 350px;
}

.terminalEditPopupDataListContainer {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 40vh;
    width: 100%;
}

.terminalEditPopupHistoryItem {
    border-bottom: 1px lightgray solid;
}

.dadataAddressInputSuggestions {
    display: flex;
    flex-direction: column;
    border: 1px lightgray solid;
}

.dadataAddressInputSuggestions a:not(:last-child) {
    border-bottom: 1px lightgray solid;
}

.dadataAddressInputSuggestions a {
    padding: 3px 5px;
    font-size: 15px;
}

.dadataAddressInputSuggestions a:hover {
    background-color: #e2e2e279;
    cursor: pointer;
}


.dadataAddressInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.dadataAddressInputContainer input {
    margin: 0;
}


