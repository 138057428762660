.myButton {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: white;
  padding: 14px;
  font-weight: bold;
  text-align: center;

  border-radius: 10px;
  background-color: #5E8ADF;
  border: 1px solid #5E8ADF;

  margin-bottom: 10px;
  cursor: pointer;
}
.myButton:focus {
  box-shadow: 0 0 1px 1px rgba(94, 137, 223, 0.39);
  outline: none;
}
.myButton:active {
  background: #0872D3;
  border: 1px solid #0872D3;
}
.myButton_big {
  width: 100%;
}
.myButton[disabled] {
  background-color: #B7C2CD;
  border: 1px solid #B7C2CD;
}
.myButton_white {
  border: 1px solid #5E8ADF;
  background: #FFF;
  color: #5E8ADF;
}

.myButton_red {
  background-color: #DC3F49;
  border-color: #DC3F49;
}

.myButton_ml-15 {
  margin-left: 15px;
}
