.toggleActivationButtonContainer {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
}

.toggleActivationButton {
    margin: 0;
    width: 180px;
}

.toggleActivationButtonLarge {
    width: 200px;
    margin-bottom: 0;
}

.removeTerminalButton {
    margin: 0;
    border-radius: 10px;
    color: red;
    cursor: pointer;
}

.editTerminalButton {
    margin: 0;
    border-radius: 10px;
    cursor: pointer;
}

.editTerminalButtonDisabled {
    opacity: 30%;
}
