.editForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 30px;
}
.editForm_block {
  display: flex;
}
.editFormField {
  position: relative;

  display: flex;
  flex-direction: column;

  margin: 20px 10px;
}
.editAvatar {
  position: relative;
}
.editAvatar_image {
  width: 164px;
  height: 164px;
  border-radius: 26px;
}
.editAvatar_preview {
  width: 150px;
  margin-top: 5px;
}
.editAvatar_delete {
  cursor: pointer;

  position: absolute;
  top: -10px;
  left: 165px;

  background: url("../assets/deleteIcon.svg");
  width: 11px;
  height: 11px;
}
.editFormField_label {
  font-family: Manrope, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(48, 55, 65, 0.4);
  margin-bottom: 15px;
}
.editFormField_label.white {
  color: #FFF;
}
.editFormField_choiceBlock {
  display: flex;
  align-items: center;

  width: 256px;
  height: 80px;

  border-radius: 10px;
  background: #F2F3F8;

  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #303741;
}
.editFormField_choiceBlock_title {
  cursor: pointer;
}
.editCheck_toggle label {
  margin: 0 20px;
}
.editFormField_choiceBlock-column {
  flex-direction: column;
  align-items: flex-start;
}
.editFormField_checkbox {
  cursor: pointer;

  width: 15px;
  height: 15px;
  margin-right: 20px;

  background: url("../assets/checkbox.svg") no-repeat;
  background-size: contain;

  align-self: center;
}
input[id="role_admin"]:checked + .editFormField_inputLabel[for="role_admin"] .editFormField_checkbox,
input[id="role_manager"]:checked + .editFormField_inputLabel[for="role_manager"] .editFormField_checkbox,
input[id="role_kioskMaster"]:checked + .editFormField_inputLabel[for="role_kioskMaster"] .editFormField_checkbox,
input[id="role_kioskOperator"]:checked + .editFormField_inputLabel[for="role_kioskOperator"] .editFormField_checkbox,
input[id="branch_canary"]:checked + .editFormField_inputLabel[for="branch_canary"] .editFormField_checkbox,
input[id="branch_test"]:checked + .editFormField_inputLabel[for="branch_test"] .editFormField_checkbox,
input[id="branch_production"]:checked + .editFormField_inputLabel[for="branch_production"] .editFormField_checkbox {
  background: url("../assets/checkboxChecked.svg") no-repeat;
}
.editFormField_inputLabel {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;

  display: flex;
  cursor: pointer;
}
input[type="checkbox"],
input[type="radio"],
input[type="file"] {
  display: none;
}
.uploadIcon {
  background: url("../assets/uploadIcon.svg") no-repeat;
  background-size: contain;
}
.editAvatar_uploadIcon {
  width: 32px;
  height: 32px;

  position: relative;
  top: 56px;
  left: 66px;
}
.editFormField_avatarLabel {
  position: relative;
  top: 58px;
  left: 29px;

  color: #5E8ADF;
  font-size: 14px;
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
}
.editFormField_avatarLabelBlock {
  display: block;
  cursor: pointer;

  border: 1px dashed #5E8ADF;
  border-radius: 26px;

  width: 164px;
  height: 164px;
}
.editFormField_avatarLabelBlock.error {
  border: 1px dashed #E13151;
}
.changePassword_button {
  width: 256px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editFormField_assemblyLabelBlock {
  width: 221px;
  padding-left: 20px;
  position: relative;

  display: flex;
  justify-content: flex-start;
}
.editAssembly_uploadIcon {
  width: 16px;
  height: 16px;

  position: absolute;
  top: 21px;
  right: 20px;
}
.editFormField_assemblyFileName {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;

  margin-right: 15px;
}

@media (max-width: 650px) {
  .editForm_block {
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .editFormField {
    margin: 20px 0;
  }
}

@media (max-width: 420px) {
  .editForm {
    padding: 0 13px;
  }
  .editFormField_choiceBlock,
  .changePassword_button {
    width: 100%;
  }
  .editFormField_assemblyLabelBlock {
    width: calc(100% - 37px);
  }
}
