@charset "UTF-8";
@font-face {
  font-family: "Manrope VF";
  src: url("/assets/fonts/manrope/variable/manropegx.ttf") format("truetype-variations");
  font-style: normal;
  font-weight: 200 800; }

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-extrabold.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-extrabold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-bold.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-semibold.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-medium.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-regular.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-light.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-extralight.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-extralight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200; }