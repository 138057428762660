html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Manrope, sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: #5E8ADF;
}
.rootNode {
  height: 100%;
}

/* забыли пароль */
.buttonLink {
  background: none;
  border: none;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #5E8ADF;
  margin-bottom: 10px;
}
.buttonLink:active {
  color: #0872D3;
}
.buttonLink:focus {
  outline: none;
  color: rgba(94, 137, 223, 0.877);
}

.sectionHeader {
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(48,55,65, 0.40);

  margin-top: 12px;
  margin-bottom: 45px;
}
.section_tableHeader {
  font-family: Manrope, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(48,55,65, 0.4);
  margin-bottom: 15px;
}

.genericItemList {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
}

.terminalsTableWrapper {
  overflow-x: auto;
}
.genericItemList_Item {
  display: flex;
  margin-right: 20px;

  font-family: Manrope, sans-serif;
  color: #303741;
  font-weight: 600;
  line-height: 80px;

  height: 80px;

  border-radius: 10px;
  position: relative;
}

.listItem_noDataText {
  text-align: center;
}
.listItem_section {
  display: flex;
  align-items: center;
  padding: 4px 0 4px 2px;
  position: relative;
}
.listItem_section .linkImage {
  width: 62px;
  height: 62px;
}
.listItem_section .linkCaption {
  margin-left: 23px;
}

.linkCaption {
  display: flex;
  align-self: center;

  color: #303741;
  margin-left: 13px;

  /*max-width: 150px;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
.linkCaption.blue {
  color: #5E8ADF;
}
.linkCaption.dialogTableFirstCellValue {
  margin-left: 0;
}
.linkCaption>input[type=checkbox] {
  display: block;
  cursor: pointer;
}
.linkImage {
  width: 66px;
  height: 66px;
  border-radius: 10px;
  overflow: hidden;
  margin: 6px 7px;
  background: #5e8adf;
  flex-shrink: 0;
}
.linkImage img,
.linkImage > div {
  width: 100%;
  height: 100%;
}

.icon_defaultUser {
  width: 17px;
  height: 17px;

  background: url("../assets/defaultUser.svg") no-repeat;
  background-size: contain;

  position: relative;
  top: 25px;
  left: 25px;
}
.icon_addUser {
  background: url("../assets/addUser.svg") no-repeat;
  background-size: contain;

  width: 37px;
  height: 17px;

  display: flex;
  align-self: center;
}
.icon_download {
  background: url("../assets/download.svg") no-repeat;
  background-size: contain;

  width: 12px;
  height: 11px;
  margin-left: 10px;

  display: flex;
  align-self: center;
}
.icon_arrowDown {
  background: url("../assets/arrowDown.svg") no-repeat;
  background-size: contain;
  display: inline-block;
  width: 16px;
  height: 10px;
}
.icon_addApp {
  background: url("../assets/addApp.svg") no-repeat;
  background-size: contain;

  width: 37px;
  height: 17px;

  display: flex;
  align-self: center;
}
.icon_add {
  background: url("../assets/add.svg") no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  display: flex;
  align-self: center;
  margin-left: 60px;
}
.icon_delete {
  background: url('../assets/deleteIcon.svg');
  background-size: contain;
  width: 11px;
  height: 11px;
}
.icon_config {
  background: url('../assets/configIcon.svg');
  background-size: contain;
  width: 15px;
  height: 15px;
}

.genericItemList_LinkPrimary {
  display: flex;

  font-family: Manrope, sans-serif;
  text-decoration: none;
  color: #5E8ADF;
  font-weight: 600;
  line-height: 80px;

  width: 100%;
  height: 80px;
  background-color: #E1EDFF;
  border-radius: 10px;
}
.genericItemList_LinkPrimary:hover {
  background-color: #CEDFFA;
}
.genericItemList_LinkPrimary_block {
  display: flex;
  margin: auto;
}

.pageSection {
  padding: 0 40px;
  height: 100%;
  position: relative;
}

.breadCrumbs {
  padding: 10px 40px 20px 40px;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #B7C2CD;
}
.breadCrumbsLink {
  text-decoration: none;
  color: #5E8ADF;
}

.span-error {
  font-size: 16px;
  color: red;
  text-align: center;
}
.span-success {
  font-size: 16px;
  color: #5E8ADF;
  text-align: center;
}
.span-error.shiftLeft,
.span-success.shiftLeft {
  text-align: left;
}

.submitSection {
  margin: 16px 0;
  width: 256px;
  height: 54px;
}

/* панель настройки и удаления пользователя, приложения */
.rightPanel_delete,
.rightPanel_config {
  cursor: pointer;
  position: relative;

  width: 41px;
  height: 41px;
}
.rightPanel_delete .icon_delete {
  position: absolute;
  top: 37%;
  left: 37%;
}
.rightPanel_delete:hover {
  background: rgba(225, 49, 81, 0.1);
  border-radius: 30px;
}
.rightPanel_delete:hover .icon_delete {
  background: url('../assets/deleteIconRed.svg');
}
.rightPanel {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.rightPanel_config .icon_config {
  position: absolute;
  top: 33%;
  left: 33%;
}
.rightPanel_config:hover {
  background: rgba(94, 138, 222, 0.15);
  border-radius: 30px;
}
.rightPanel_config:hover .icon_config {
  background: url('../assets/configIconBlue.svg');
}

/* toggle */
.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl-btn {
  outline: 0;
  display: block;
  width: 15px;
  height: 25px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tgl-btn:after, .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 100%;
  height: 50%;
}
.tgl-btn:after {
  bottom: -50%;
}
.tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  bottom: 0;
}
.tgl-light + .tgl-btn {
  background: #B7C2CD;
  border-radius: 7px;
  padding: 2px;
  transition: all .4s ease;
}
.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all .2s ease;
}
.tgl-light:checked + .tgl-btn {
  background: #5E8ADF;
}

.token-field {
  width: 300px;
}

.textarea-license {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;
  padding: 15px;
  border-radius: 2px;
  border: 1px solid #DDE0EA;
  margin-bottom: 10px;
  width: 300px;
  height: 250px;
}

/* Анимация появления плашки с информацией об обновлении экрана и терминала */
@-webkit-keyframes show {
  0% {
    opacity: 0;
    top: -50px;
    left: 40px;
  }
  50% {
    top: 120px;
    opacity: 1;
  }
  100% {
    top: 100px;
  }
}
@keyframes show {
  0% {
    opacity: 0;
    top: -50px;
    left: 40px;
  }
  50% {
    top: 120px;
    opacity: 1;
  }
  100% {
    top: 100px;
  }
}

.noRolePage {
  color: #303741;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noRolePage_title {
  font-size: 20px;
}

@media (max-width: 1020px) {
  .listItem_section {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .section_tableHeader {
    white-space: pre-wrap;
  }
}

@media (max-width: 768px) {
  .listItem_section {
    align-items: flex-start;
    padding: 0;
  }
  .listItem_sectionColumn {
    flex-direction: column;
  }
  .listItem_sectionColumn_part {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin: 10px 0;
  }
  .listItem_sectionColumn_part .section_tableHeader {
    margin-bottom: 0;
    align-self: center;
  }
  .rightPanel,
  .linkImage {
    align-self: center;
  }
}

@media (max-width: 550px) {
  .breadCrumbs {
    padding: 18px 30px;
  }
}

@media (max-width: 420px) {
  .pageSection {
    padding: 40px 7px 0;
  }
  .genericItemList_Item {
    margin-right: 0;
  }
  .linkCaption {
    margin-left: 20px;
  }
  .breadCrumbs {
    padding: 18px 13px;
  }
  .submitSection {
    width: 100%;
  }
}
