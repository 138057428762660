.myTextInput {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;
  padding: 15px;
  border-radius: 2px;
  border: 1px solid #DDE0EA;
  margin-bottom: 10px;
}
.myTextInput:focus {
  outline: none;
  border: 1px solid #5E8ADF;
}
.inputError {
  border: 2px solid tomato;
}

.myButton {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: white;
  padding: 14px;
  font-weight: bold;
  text-align: center;

  border-radius: 10px;
  background-color: #5E8ADF;
  border: 1px solid #5E8ADF;

  margin-bottom: 10px;
  cursor: pointer;
}
.myButton:focus {
  box-shadow: 0 0 1px 1px rgba(94, 137, 223, 0.39);
  outline: none;
}
.myButton:active {
  background: #0872D3;
  border: 1px solid #0872D3;
}
.myButton_big {
  width: 100%;
}
.myButton[disabled] {
  background-color: #B7C2CD;
  border: 1px solid #B7C2CD;
}
.myButton_white {
  border: 1px solid #5E8ADF;
  background: #FFF;
  color: #5E8ADF;
}

.myButton_red {
  background-color: #DC3F49;
  border-color: #DC3F49;
}

.myButton_ml-15 {
  margin-left: 15px;
}

.loginSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loginForm {
  display: flex;
  flex-direction: column;
  width: 440px;
  padding: 60px;
}

.mainNavMenu {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 85px;
  border-bottom: 1px solid #B7C2CD;
  display: flex;
  flex-direction: row;
  padding: 0 40px;
}
.mainLink {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #303741;
}
.mainLink_active {
  color: #5E8ADF;
}
.mainButton__red {
  cursor: pointer;
  background: none;
  border: none;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #DC3F49;
  padding: 0;
  display: inline;
}
.mainNavList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mainNavListItem {
  margin-right: 50px;
}
.mainNavListItem__right {
  margin-left: 50px;
}
.mainNavListItem__avatar {
  width: 66px;
  height: 66px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: -40px;
  flex-shrink: 0;
}
.mainNavListItem__avatar img {
  width: 100%;
  height: 100%;
}
.mainNavList__right {
  margin-left: auto;
}
.mainNav_mobileTitle {
  display: flex;
  align-self: center;
  margin-left: 13px;
}

@media (max-width: 855px) {
  .mainNavListItem__right {
    margin-left: 30px;
  }
  .mainNavListItem__avatar {
    margin-right: -20px;
  }
}

@media (max-width: 740px) {
  .mainNavMenu {
    overflow-y: scroll;
  }
  .mainNavListItem__right {
    white-space: nowrap;
    padding-right: 40px;
    margin-left: 0;
  }
  .mainNavListItem__right.mainNavListItem__rightPartial {
    padding-right: 0;
  }
  .mainNavListItem__avatar {
    margin-right: 5px;
  }
}

@media (max-width: 550px) {
  .mainNavMenu {
    height: 60px;
  }
  .mainNavListItem {
    margin-right: 20px;
  }
  .mainNavListItem__avatar {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 420px) {
  .mainNavMenu {
    padding: 0;
    overflow: visible;
  }
  .mainNavListItem__right,
  .mainNavListItem__right.mainNavListItem__rightPartial {
    padding-right: 13px;
    margin: 0;
  }
  [data-reach-listbox-input].mainNavList {
    width: 169px;
    border: none;
    padding: 17px 13px;
    margin-right: 0;
  }
  [data-reach-listbox-popover].mainNavList_popover {
    width: 197px;
    padding-bottom: 10px;

    border-radius: 0 0 10px 10px;
    border: 1px solid #f2f3f8;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

    z-index: 1;
  }
  [data-reach-listbox-option].mainNavListItem {
    padding: 8px 13px;
  }
  .mainNavListItem a {
    color: #303741;
    width: 100%;
    display: block;
  }
  .mainNavListItem.hidden {
    display: none;
  }
  [data-current].mainNavListItem a {
    color: #5E8ADF;
  }
  .mainNavList_popover [data-reach-listbox-option][data-value="default"] {
    border-radius: 0;
    padding: 17px 13px;
  }
  .mainNavList_popover [data-reach-listbox-option][aria-disabled] {
    opacity: 1;
  }
}
.deletePopupScreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;

    z-index: 1;
    overflow:  hidden;
}
.deletePopup_cover {
    background: rgba(0, 0, 0, 0.5);

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}
.deletePopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;

    position: relative;
    top: 35%;

    margin: 0 auto;
    padding: 10px;
    width: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.deletePopup_labelBlock {
    display: flex;
    flex-direction: column;
}
.deletePopup_label {
    text-align: center;
    line-height: 50px;
}
.deletePopup_choiceBlock .myButton:first-child {
    margin-right: 20px;
}

@media (max-width: 670px) {
    .deletePopup {
        width: calc(100% - 70px);
    }
}

@media (max-width: 550px) {
    .deletePopup_label {
        line-height: 30px;
    }
}
.appSection{
  padding: 0 40px;
  margin-top: 30px;
}
.appSection_info {
  display: flex;
  flex-direction: column;
}
.appSection_input {
  width: 60vw;
}
.appSection_textarea {
  width: 870px;
  outline: none;
  resize: none;
  border: 1px solid #DDE0EA;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;
  padding: 15px;
}
.appSection_textarea.error {
  border: 2px solid tomato;
}
.appMenuItem__add__tiny {
  display: flex;
  height: 70px;
  width: 220px;
  border-radius: 10px;
  margin: 0 10px 20px 0;
}
.appUpdatePopup {
  position: absolute;
  top: 100px;
  left: 40px;
  width: 62%;
  padding: 17px 0 17px 17px;
  display: flex;
  justify-content: space-between;
  font-family: Manrope3, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #303741;
  background-color: #FFD823;
  box-shadow: 0 7px 10px rgba(255, 216, 35, 0.55);
  border-radius: 3px;
  -webkit-animation: show 0.7s forwards;
          animation: show 0.7s forwards;
}
.appUpdatePopupClose {
  width: 18px;
  height: 18px;
  margin-right: 20px;
  font-weight: bold;
  color: #000000;
  opacity: 0.3;
  cursor: pointer;
  transition: 0.4s all;
}
.appUpdatePopupClose:hover {
  opacity: 0.7;
}

.appPageHeader {
  margin-left: 10px;
}
.appItem_listHeader {
  width: 726px;
  margin-left: 9px;
  display: flex;
  position: relative;
}
.genericItemList_Item.appItem_listItem {
  display: block;
  width: 726px;
  height: 82px;
  margin-bottom: 10px;
}
.appItem_listItem:nth-child(2n) {
  background: #F2F3F8;
}
.appItem_nameHeader {
  position: absolute;
  left: 90px;
}
.appItem_nameValue.linkCaption {
  max-width: 250px;
  display: block;
}
.appItem_date {
  position: absolute;
  left: 360px;
}
.appItem_addButton {
  margin: 30px 0;
  width: 348px;
}
.editFormField.appField {
  width: 900px;
}

@media (max-width: 980px) {
  .editFormField.appField {
    width: calc(100% - 20px);
  }
  .appSection_textarea {
    width: calc(100% - 35px);
  }
}

@media (max-width: 768px) {
  .genericItemList_Item.appItem_listItem,
  .appItem_listHeader {
    width: 100%;
  }
  .appItem_nameValue.linkCaption {
    max-width: 31%;
  }
  .appItem_date {
    left: calc(100% - 370px);
  }
  .appItem_listItem .listItem_section .linkImage,
  .appItem_listItem .listItem_section .rightPanel {
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  .appItem_nameValue.linkCaption {
    max-width: 60%;
  }
}

@media (max-width: 550px) {
  .appItem_nameValue.linkCaption {
    max-width: 50%;
  }
}

@media (max-width: 440px) {
  .appItem_nameValue.linkCaption {
    max-width: 40%;
  }
}

@media (max-width: 420px) {
  .appItem_addButton {
    width: 100%;
    margin-bottom: 8px;
  }
  .appItem_listItem {
    background: #F2F3F8;
  }
  .editFormField.appField {
    width: 100%;
  }
  .appSection_textarea {
    width: calc(100% - 32px);
  }
}
.editForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 30px;
}
.editForm_block {
  display: flex;
}
.editFormField {
  position: relative;

  display: flex;
  flex-direction: column;

  margin: 20px 10px;
}
.editAvatar {
  position: relative;
}
.editAvatar_image {
  width: 164px;
  height: 164px;
  border-radius: 26px;
}
.editAvatar_preview {
  width: 150px;
  margin-top: 5px;
}
.editAvatar_delete {
  cursor: pointer;

  position: absolute;
  top: -10px;
  left: 165px;

  background: url(/static/media/deleteIcon.f9c7aec8.svg);
  width: 11px;
  height: 11px;
}
.editFormField_label {
  font-family: Manrope, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(48, 55, 65, 0.4);
  margin-bottom: 15px;
}
.editFormField_label.white {
  color: #FFF;
}
.editFormField_choiceBlock {
  display: flex;
  align-items: center;

  width: 256px;
  height: 80px;

  border-radius: 10px;
  background: #F2F3F8;

  font-family: Manrope, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #303741;
}
.editFormField_choiceBlock_title {
  cursor: pointer;
}
.editCheck_toggle label {
  margin: 0 20px;
}
.editFormField_choiceBlock-column {
  flex-direction: column;
  align-items: flex-start;
}
.editFormField_checkbox {
  cursor: pointer;

  width: 15px;
  height: 15px;
  margin-right: 20px;

  background: url(/static/media/checkbox.d78fded4.svg) no-repeat;
  background-size: contain;

  align-self: center;
}
input[id="role_admin"]:checked + .editFormField_inputLabel[for="role_admin"] .editFormField_checkbox,
input[id="role_manager"]:checked + .editFormField_inputLabel[for="role_manager"] .editFormField_checkbox,
input[id="role_kioskMaster"]:checked + .editFormField_inputLabel[for="role_kioskMaster"] .editFormField_checkbox,
input[id="role_kioskOperator"]:checked + .editFormField_inputLabel[for="role_kioskOperator"] .editFormField_checkbox,
input[id="branch_canary"]:checked + .editFormField_inputLabel[for="branch_canary"] .editFormField_checkbox,
input[id="branch_test"]:checked + .editFormField_inputLabel[for="branch_test"] .editFormField_checkbox,
input[id="branch_production"]:checked + .editFormField_inputLabel[for="branch_production"] .editFormField_checkbox {
  background: url(/static/media/checkboxChecked.b9da409d.svg) no-repeat;
}
.editFormField_inputLabel {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;

  display: flex;
  cursor: pointer;
}
input[type="checkbox"],
input[type="radio"],
input[type="file"] {
  display: none;
}
.uploadIcon {
  background: url(/static/media/uploadIcon.2b7f37bd.svg) no-repeat;
  background-size: contain;
}
.editAvatar_uploadIcon {
  width: 32px;
  height: 32px;

  position: relative;
  top: 56px;
  left: 66px;
}
.editFormField_avatarLabel {
  position: relative;
  top: 58px;
  left: 29px;

  color: #5E8ADF;
  font-size: 14px;
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
}
.editFormField_avatarLabelBlock {
  display: block;
  cursor: pointer;

  border: 1px dashed #5E8ADF;
  border-radius: 26px;

  width: 164px;
  height: 164px;
}
.editFormField_avatarLabelBlock.error {
  border: 1px dashed #E13151;
}
.changePassword_button {
  width: 256px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editFormField_assemblyLabelBlock {
  width: 221px;
  padding-left: 20px;
  position: relative;

  display: flex;
  justify-content: flex-start;
}
.editAssembly_uploadIcon {
  width: 16px;
  height: 16px;

  position: absolute;
  top: 21px;
  right: 20px;
}
.editFormField_assemblyFileName {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;

  margin-right: 15px;
}

@media (max-width: 650px) {
  .editForm_block {
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .editFormField {
    margin: 20px 0;
  }
}

@media (max-width: 420px) {
  .editForm {
    padding: 0 13px;
  }
  .editFormField_choiceBlock,
  .changePassword_button {
    width: 100%;
  }
  .editFormField_assemblyLabelBlock {
    width: calc(100% - 37px);
  }
}

.assemblyItem_title {
    margin: 0 30px;
}
.assemblyItem_listItem:nth-child(2n) {
    background: #F2F3F8;
}
.assemblyItem_listItem {
    width: 900px;
    height: 40px;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px;

    display: flex;
    align-items: center;
}
.assemblyItem_listItem .listItem_section .linkCaption {
    display: block;
    font-size: 14px;
    margin-left: 0;
    color: #606D7A;
}
.assemblyItem_download {
    width: 95px;
    height: 24px;

    display: flex;
    cursor: pointer;

    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}
.assemblyItem_listHeader {
    width: 940px;
    margin-left: 20px;
    display: flex;
    position: relative;
}
.assembly-version {
    position: absolute;
    left: 143px;
}
.assembly-date {
    position: absolute;
    left: 245px;
    width: 160px;
}
.assembly-setup {
    position: absolute;
    left: 430px;

    width: 160px;
}
.assembly-description {
    position: absolute;
    left: 602px;

    width: 163px;

    cursor: pointer;
    display: block;
    display: -webkit-box !important;
    max-width: 163px;
    -webkit-line-clamp: 2;
    max-height: 80px;
    -webkit-box-orient: vertical;
    white-space: pre-wrap !important;
}
.assembly-download {
    position: absolute;
    left: 810px;
}
.assembly-fullDescription {
    width: 288px;

    position: absolute;
    left: 600px;
    bottom: 47px;

    color: #FFF;
    padding: 10px;
    display: none;

    background: rgba(48, 55, 65, 0.8);
    border-radius: 4px;
}
.assembly-fullDescription:after {
    display: block;
    content: "";

    width: 20px;
    height: 15px;

    background-size: contain;
    background: url(/static/media/corner.7a3c95b0.svg) no-repeat;

    position: relative;
    top: 25px;
    left: 10px
}
.assemblyFile_loading {
    margin-left: 20px;
    font-family: Open Sans, sans-serif;
}

@media (max-width: 1020px) {
    .assemblyItem_listItem,
    .assemblyItem_listHeader {
        width: calc(100% - 40px);
    }
    .assembly-download {
        left: calc(100% - 70px);
    }
}

@media (max-width: 1000px) {
    .assemblyItem_listHeader {
        margin-bottom: 15px;
    }
    .assembly-setup,
    .assembly-date {
        max-width: 100px;
    }
}

@media (max-width: 960px) {
    .assembly-description {
        left: calc(100% - 250px);
    }
    .assembly-fullDescription {
        left: calc(100% - 248px);
    }
}

@media (max-width: 920px) {
    .assembly-setup {
        left: calc(100% - 350px);
    }
}

@media (max-width: 830px) {
    .assembly-date {
        left: calc(100% - 460px);
    }
}

@media (max-width: 800px) {
    .assembly-version {
        left: calc(100% - 530px);
    }
}

@media (max-width: 768px) {
    .assemblyItem_listItem {
        height: auto;
        padding: 18px 13px;
        width: calc(100% - 30px);
    }
    .assemblyItem_listItem:nth-child(even) {
        background: none;
    }
    .assemblyItem_listItem:nth-child(odd) {
        background: #F2F3F8;
    }
    .assemblyItem_download {
        text-align: right;
        width: auto;
        width: initial;
    }
    .assembly-description {
        width: calc(100% - 135px);
        max-width: calc(100% - 140px);
        text-align: right;
        position: static;
        position: initial;
        display: block !important;
    }
}

@media (max-width: 550px) {
    .assemblyItem_listItem {
        width: 100%;
        margin-left: -13px;
    }
}
.genericItemList.userList {
    flex-direction: row;
    flex-wrap: wrap;
}
.genericItemList_Item.addUser {
    width: 438px;
    margin-bottom: 20px;
    background: #F2F3F8;
}
.editFormField.userField {
    width: 440px;
}

@media (max-width: 980px) {
    .editFormField.userField {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .genericItemList_Item.addUser {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .editFormField.userField {
        margin: 0 10px 20px;
    }
}

@media (max-width: 550px) {
    .editFormField.userField {
        margin: 0 0 20px;
    }
}
.statPage {
    margin: 30px 0;
}
.statPage_header {
    margin-left: 10px;
    margin-bottom: 30px;
}
.statPage_header:not(:first-child) {
    margin-top: 30px;
}
.statPage_form {
    display: flex;
    margin-bottom: 40px;
}
.statPage_chart {
    overflow-x: scroll;
}
.statPage_assemblyItem {
    width: 522px;
}
.statPage_assemblyItem_version {
    position: absolute;
    left: 200px;
}
.statPage_assemblyItem_setup {
    position: absolute;
    left: 330px
}
.section_tableHeader.statPage_assemblyItem_branch,
.section_tableHeader.statPage_assemblyItem_version,
.section_tableHeader.statPage_assemblyItem_setup {
    cursor: pointer;
}

/* стили выпадающих списков*/
[data-reach-listbox-input] {
    border: 1px solid #5E8ADF;
    border-radius: 10px;
    padding: 17px 27px;
    margin-right: 20px;

    cursor: pointer;
    width: 211px;

    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #5E8ADF;

    position: relative;
}
[data-reach-listbox-button] {
    border: none;
    padding: 0;
}
[data-reach-listbox-arrow] {
    position: absolute;
    right: 27px;
    top: 20px;
}
[data-reach-listbox-popover] {
    width: 265px;
    border: 1px solid #5E8ADF;
    border-radius: 10px;
    padding: 0;

    position: absolute;
    top: -1px;
    left: -1px;
}
[data-reach-listbox-option] {
    padding: 8px 27px;

    color: #303741;
}
[data-reach-listbox-option]:hover {
    background: none;
    color: #606d99;
}
[data-reach-listbox-option][data-current] {
    color: #5E8ADF;
}
[data-reach-listbox-option][aria-selected] {
    background: none;
}
[data-reach-listbox-option][data-value="default"] {
    background: #E1EDFF;
    color: #5E8ADF;

    border-radius: 10px 10px 0 0;
    padding: 16px 27px;
    opacity: 1;
}
.listboxOption_arrowIcon {
    transform: scaleY(-1);

    position: absolute;
    top: 23px;
    right: 27px;
}

@media (max-width: 620px) {
    .statPage_assemblyItem {
        width: calc(100% - 40px);
    }
}

@media (max-width: 565px) {
    .statPage_assemblyItem_setup {
        left: calc(100% - 140px)
    }
}

@media (max-width: 510px) {
    .statPage {
        margin: 0;
    }
    .statPage.pageSection {
        padding-top: 0;
    }
    .statPage_assemblyItem_version {
        left: calc(100% - 210px)
    }
}
.dataGridHeader {
  margin-left: 20px;
}

.toggleActivationButtonContainer {
    display: flex;
    flex: 1 1;
    justify-content: flex-start;
    align-items: center;
}

.toggleActivationButton {
    margin: 0;
    width: 180px;
}

.toggleActivationButtonLarge {
    width: 200px;
    margin-bottom: 0;
}

.removeTerminalButton {
    margin: 0;
    border-radius: 10px;
    color: red;
    cursor: pointer;
}

.editTerminalButton {
    margin: 0;
    border-radius: 10px;
    cursor: pointer;
}

.editTerminalButtonDisabled {
    opacity: 30%;
}

.licenseCodes__separator {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 30px;
}

.valueSpan {
  display: inline-block;
  margin-right: 10px;
}

.shifterButton {
  font-family: Manrope, sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;

  border-radius: 4px;
  background-color: #5E8ADF;
  border: 1px solid #5E8ADF;

  margin-left: 5px;
  cursor: pointer;
}
.shifterButton:focus {
  box-shadow: 0 0 1px 1px rgba(94, 137, 223, 0.39);
  outline: none;
}
.shifterButton:active {
  background: #0872D3;
  border: 1px solid #0872D3;
}
.shifterButton[disabled] {
  background-color: #B7C2CD;
  border: 1px solid #B7C2CD;
}

.licenseCodes__row-container {
  height: 32px;
}
.licenseCodes__row-textarea {
  margin-bottom: -3px;
  vertical-align: bottom;
  width: 175px;
}

.licensePopupBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:  hidden;
    background: rgba(0, 0, 0, 0.5);
}

.licensePopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    padding: 10px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.licensePopupInputContainer {
    width: 90%;
    justify-content: start;
    display: flex;
    flex-direction: column;
}

.licensePopupSaveButton {
    margin-top: 25px;
    margin-left: 15px;
    width: 200px;
}


.map {
    width: 100%;
    height: calc(100vh - 60px)
}
.terminalsMapPopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    padding: 10px;
    height: 70vh;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.terminalsMapPointInfoBlock {
    width: 40vw;
    position: absolute;
    top: 0;
    z-index: 3;
    background-color: #FFF;
    border-radius: 10px;
    padding: 0 20px 15px 20px;
}

.terminalsMapPointInfoBlock h3 {
    text-align: center;
}

.mapPointInfoRow p {
    display: inline-block;
    margin: 0;
}

.mapPointInfoRow p:first-child {
    margin-right: 10px;
    font-weight: bold;
}



.terminalEditPopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    padding: 10px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
}

.terminalEditPopupDataContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.terminalEditPopup h1 {
    text-align: center;
}

.terminalEditPopupButtonContainer {
    justify-content: center;
    align-self: center;
}

.terminalEditPopupInputContainer {
    width: 350px;
    justify-content: start;
    display: flex;
    flex-direction: column;
}

.terminalEditPopupEditDataBlock:not(:first-child) {
    margin-left: 40px;
}

.terminalEditPopupEditDataBlock h1 {
    text-align: center;
}

.terminalEditPopupListDataBlock {
    width: 350px;
}

.terminalEditPopupDataListContainer {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 40vh;
    width: 100%;
}

.terminalEditPopupHistoryItem {
    border-bottom: 1px lightgray solid;
}

.dadataAddressInputSuggestions {
    display: flex;
    flex-direction: column;
    border: 1px lightgray solid;
}

.dadataAddressInputSuggestions a:not(:last-child) {
    border-bottom: 1px lightgray solid;
}

.dadataAddressInputSuggestions a {
    padding: 3px 5px;
    font-size: 15px;
}

.dadataAddressInputSuggestions a:hover {
    background-color: #e2e2e279;
    cursor: pointer;
}


.dadataAddressInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.dadataAddressInputContainer input {
    margin: 0;
}



.moreDetailedTerminalPopup {
    max-height: 90vh;
    overflow: hidden;
}
.moreDetailedTerminalPopupDataContainer {
    overflow-y: auto;
}

.dialogGroupActionContainer {
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.dialogLinksContainer {
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    border-bottom: 1px solid #B7C2CD;
}

.dialogChangeTableBtn {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;

    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #303741;

    box-sizing: border-box;
    padding-bottom: 5px;
    border-bottom: 2px transparent solid;
}

.dialogChangeTableBtnActive {
    color: #5E8ADF;
    border-bottom-color: #5E8ADF;
}

.dialogForActivateCount {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: -16px;
    top: -10px;
    background-color: #5E8ADF;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 800;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Manrope, sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: #5E8ADF;
}
.rootNode {
  height: 100%;
}

/* забыли пароль */
.buttonLink {
  background: none;
  border: none;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #5E8ADF;
  margin-bottom: 10px;
}
.buttonLink:active {
  color: #0872D3;
}
.buttonLink:focus {
  outline: none;
  color: rgba(94, 137, 223, 0.877);
}

.sectionHeader {
  font-family: Manrope, sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(48,55,65, 0.40);

  margin-top: 12px;
  margin-bottom: 45px;
}
.section_tableHeader {
  font-family: Manrope, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(48,55,65, 0.4);
  margin-bottom: 15px;
}

.genericItemList {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
}

.terminalsTableWrapper {
  overflow-x: auto;
}
.genericItemList_Item {
  display: flex;
  margin-right: 20px;

  font-family: Manrope, sans-serif;
  color: #303741;
  font-weight: 600;
  line-height: 80px;

  height: 80px;

  border-radius: 10px;
  position: relative;
}

.listItem_noDataText {
  text-align: center;
}
.listItem_section {
  display: flex;
  align-items: center;
  padding: 4px 0 4px 2px;
  position: relative;
}
.listItem_section .linkImage {
  width: 62px;
  height: 62px;
}
.listItem_section .linkCaption {
  margin-left: 23px;
}

.linkCaption {
  display: flex;
  align-self: center;

  color: #303741;
  margin-left: 13px;

  /*max-width: 150px;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
.linkCaption.blue {
  color: #5E8ADF;
}
.linkCaption.dialogTableFirstCellValue {
  margin-left: 0;
}
.linkCaption>input[type=checkbox] {
  display: block;
  cursor: pointer;
}
.linkImage {
  width: 66px;
  height: 66px;
  border-radius: 10px;
  overflow: hidden;
  margin: 6px 7px;
  background: #5e8adf;
  flex-shrink: 0;
}
.linkImage img,
.linkImage > div {
  width: 100%;
  height: 100%;
}

.icon_defaultUser {
  width: 17px;
  height: 17px;

  background: url(/static/media/defaultUser.ec207f56.svg) no-repeat;
  background-size: contain;

  position: relative;
  top: 25px;
  left: 25px;
}
.icon_addUser {
  background: url(/static/media/addUser.8fb51e90.svg) no-repeat;
  background-size: contain;

  width: 37px;
  height: 17px;

  display: flex;
  align-self: center;
}
.icon_download {
  background: url(/static/media/download.e89b9445.svg) no-repeat;
  background-size: contain;

  width: 12px;
  height: 11px;
  margin-left: 10px;

  display: flex;
  align-self: center;
}
.icon_arrowDown {
  background: url(/static/media/arrowDown.d54fe06b.svg) no-repeat;
  background-size: contain;
  display: inline-block;
  width: 16px;
  height: 10px;
}
.icon_addApp {
  background: url(/static/media/addApp.4b1a5a01.svg) no-repeat;
  background-size: contain;

  width: 37px;
  height: 17px;

  display: flex;
  align-self: center;
}
.icon_add {
  background: url(/static/media/add.9035363f.svg) no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  display: flex;
  align-self: center;
  margin-left: 60px;
}
.icon_delete {
  background: url(/static/media/deleteIcon.f9c7aec8.svg);
  background-size: contain;
  width: 11px;
  height: 11px;
}
.icon_config {
  background: url(/static/media/configIcon.aa69197a.svg);
  background-size: contain;
  width: 15px;
  height: 15px;
}

.genericItemList_LinkPrimary {
  display: flex;

  font-family: Manrope, sans-serif;
  text-decoration: none;
  color: #5E8ADF;
  font-weight: 600;
  line-height: 80px;

  width: 100%;
  height: 80px;
  background-color: #E1EDFF;
  border-radius: 10px;
}
.genericItemList_LinkPrimary:hover {
  background-color: #CEDFFA;
}
.genericItemList_LinkPrimary_block {
  display: flex;
  margin: auto;
}

.pageSection {
  padding: 0 40px;
  height: 100%;
  position: relative;
}

.breadCrumbs {
  padding: 10px 40px 20px 40px;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #B7C2CD;
}
.breadCrumbsLink {
  text-decoration: none;
  color: #5E8ADF;
}

.span-error {
  font-size: 16px;
  color: red;
  text-align: center;
}
.span-success {
  font-size: 16px;
  color: #5E8ADF;
  text-align: center;
}
.span-error.shiftLeft,
.span-success.shiftLeft {
  text-align: left;
}

.submitSection {
  margin: 16px 0;
  width: 256px;
  height: 54px;
}

/* панель настройки и удаления пользователя, приложения */
.rightPanel_delete,
.rightPanel_config {
  cursor: pointer;
  position: relative;

  width: 41px;
  height: 41px;
}
.rightPanel_delete .icon_delete {
  position: absolute;
  top: 37%;
  left: 37%;
}
.rightPanel_delete:hover {
  background: rgba(225, 49, 81, 0.1);
  border-radius: 30px;
}
.rightPanel_delete:hover .icon_delete {
  background: url(/static/media/deleteIconRed.ac9f1eb4.svg);
}
.rightPanel {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.rightPanel_config .icon_config {
  position: absolute;
  top: 33%;
  left: 33%;
}
.rightPanel_config:hover {
  background: rgba(94, 138, 222, 0.15);
  border-radius: 30px;
}
.rightPanel_config:hover .icon_config {
  background: url(/static/media/configIconBlue.a1167802.svg);
}

/* toggle */
.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl-btn {
  outline: 0;
  display: block;
  width: 15px;
  height: 25px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tgl-btn:after, .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 100%;
  height: 50%;
}
.tgl-btn:after {
  bottom: -50%;
}
.tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  bottom: 0;
}
.tgl-light + .tgl-btn {
  background: #B7C2CD;
  border-radius: 7px;
  padding: 2px;
  transition: all .4s ease;
}
.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all .2s ease;
}
.tgl-light:checked + .tgl-btn {
  background: #5E8ADF;
}

.token-field {
  width: 300px;
}

.textarea-license {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;
  padding: 15px;
  border-radius: 2px;
  border: 1px solid #DDE0EA;
  margin-bottom: 10px;
  width: 300px;
  height: 250px;
}

/* Анимация появления плашки с информацией об обновлении экрана и терминала */
@-webkit-keyframes show {
  0% {
    opacity: 0;
    top: -50px;
    left: 40px;
  }
  50% {
    top: 120px;
    opacity: 1;
  }
  100% {
    top: 100px;
  }
}
@keyframes show {
  0% {
    opacity: 0;
    top: -50px;
    left: 40px;
  }
  50% {
    top: 120px;
    opacity: 1;
  }
  100% {
    top: 100px;
  }
}

.noRolePage {
  color: #303741;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noRolePage_title {
  font-size: 20px;
}

@media (max-width: 1020px) {
  .listItem_section {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .section_tableHeader {
    white-space: pre-wrap;
  }
}

@media (max-width: 768px) {
  .listItem_section {
    align-items: flex-start;
    padding: 0;
  }
  .listItem_sectionColumn {
    flex-direction: column;
  }
  .listItem_sectionColumn_part {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin: 10px 0;
  }
  .listItem_sectionColumn_part .section_tableHeader {
    margin-bottom: 0;
    align-self: center;
  }
  .rightPanel,
  .linkImage {
    align-self: center;
  }
}

@media (max-width: 550px) {
  .breadCrumbs {
    padding: 18px 30px;
  }
}

@media (max-width: 420px) {
  .pageSection {
    padding: 40px 7px 0;
  }
  .genericItemList_Item {
    margin-right: 0;
  }
  .linkCaption {
    margin-left: 20px;
  }
  .breadCrumbs {
    padding: 18px 13px;
  }
  .submitSection {
    width: 100%;
  }
}

@charset "UTF-8";
@font-face {
  font-family: "Manrope VF";
  src: url("/assets/fonts/manrope/variable/manropegx.ttf") format("truetype-variations");
  font-style: normal;
  font-weight: 200 800; }

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-extrabold.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-extrabold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-bold.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-semibold.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-medium.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-regular.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-light.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300; }
@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/manrope/web/manrope-extralight.woff2") format("woff2"),
  url("/assets/fonts/manrope/ttf/manrope-extralight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200; }

