.deletePopupScreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;

    z-index: 1;
    overflow:  hidden;
}
.deletePopup_cover {
    background: rgba(0, 0, 0, 0.5);

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}
.deletePopup {
    background: #FFF;
    border-radius: 10px;
    z-index: 2;

    position: relative;
    top: 35%;

    margin: 0 auto;
    padding: 10px;
    width: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.deletePopup_labelBlock {
    display: flex;
    flex-direction: column;
}
.deletePopup_label {
    text-align: center;
    line-height: 50px;
}
.deletePopup_choiceBlock .myButton:first-child {
    margin-right: 20px;
}

@media (max-width: 670px) {
    .deletePopup {
        width: calc(100% - 70px);
    }
}

@media (max-width: 550px) {
    .deletePopup_label {
        line-height: 30px;
    }
}