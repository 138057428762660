.appSection{
  padding: 0 40px;
  margin-top: 30px;
}
.appSection_info {
  display: flex;
  flex-direction: column;
}
.appSection_input {
  width: 60vw;
}
.appSection_textarea {
  width: 870px;
  outline: none;
  resize: none;
  border: 1px solid #DDE0EA;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;
  padding: 15px;
}
.appSection_textarea.error {
  border: 2px solid tomato;
}
.appMenuItem__add__tiny {
  display: flex;
  height: 70px;
  width: 220px;
  border-radius: 10px;
  margin: 0 10px 20px 0;
}
.appUpdatePopup {
  position: absolute;
  top: 100px;
  left: 40px;
  width: 62%;
  padding: 17px 0 17px 17px;
  display: flex;
  justify-content: space-between;
  font-family: Manrope3, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #303741;
  background-color: #FFD823;
  box-shadow: 0 7px 10px rgba(255, 216, 35, 0.55);
  border-radius: 3px;
  -webkit-animation: show 0.7s forwards;
          animation: show 0.7s forwards;
}
.appUpdatePopupClose {
  width: 18px;
  height: 18px;
  margin-right: 20px;
  font-weight: bold;
  color: #000000;
  opacity: 0.3;
  cursor: pointer;
  transition: 0.4s all;
}
.appUpdatePopupClose:hover {
  opacity: 0.7;
}

.appPageHeader {
  margin-left: 10px;
}
.appItem_listHeader {
  width: 726px;
  margin-left: 9px;
  display: flex;
  position: relative;
}
.genericItemList_Item.appItem_listItem {
  display: block;
  width: 726px;
  height: 82px;
  margin-bottom: 10px;
}
.appItem_listItem:nth-child(2n) {
  background: #F2F3F8;
}
.appItem_nameHeader {
  position: absolute;
  left: 90px;
}
.appItem_nameValue.linkCaption {
  max-width: 250px;
  display: block;
}
.appItem_date {
  position: absolute;
  left: 360px;
}
.appItem_addButton {
  margin: 30px 0;
  width: 348px;
}
.editFormField.appField {
  width: 900px;
}

@media (max-width: 980px) {
  .editFormField.appField {
    width: calc(100% - 20px);
  }
  .appSection_textarea {
    width: calc(100% - 35px);
  }
}

@media (max-width: 768px) {
  .genericItemList_Item.appItem_listItem,
  .appItem_listHeader {
    width: 100%;
  }
  .appItem_nameValue.linkCaption {
    max-width: 31%;
  }
  .appItem_date {
    left: calc(100% - 370px);
  }
  .appItem_listItem .listItem_section .linkImage,
  .appItem_listItem .listItem_section .rightPanel {
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  .appItem_nameValue.linkCaption {
    max-width: 60%;
  }
}

@media (max-width: 550px) {
  .appItem_nameValue.linkCaption {
    max-width: 50%;
  }
}

@media (max-width: 440px) {
  .appItem_nameValue.linkCaption {
    max-width: 40%;
  }
}

@media (max-width: 420px) {
  .appItem_addButton {
    width: 100%;
    margin-bottom: 8px;
  }
  .appItem_listItem {
    background: #F2F3F8;
  }
  .editFormField.appField {
    width: 100%;
  }
  .appSection_textarea {
    width: calc(100% - 32px);
  }
}