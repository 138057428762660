.mainNavMenu {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 85px;
  border-bottom: 1px solid #B7C2CD;
  display: flex;
  flex-direction: row;
  padding: 0 40px;
}
.mainLink {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #303741;
}
.mainLink_active {
  color: #5E8ADF;
}
.mainButton__red {
  cursor: pointer;
  background: none;
  border: none;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #DC3F49;
  padding: 0;
  display: inline;
}
.mainNavList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mainNavListItem {
  margin-right: 50px;
}
.mainNavListItem__right {
  margin-left: 50px;
}
.mainNavListItem__avatar {
  width: 66px;
  height: 66px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: -40px;
  flex-shrink: 0;
}
.mainNavListItem__avatar img {
  width: 100%;
  height: 100%;
}
.mainNavList__right {
  margin-left: auto;
}
.mainNav_mobileTitle {
  display: flex;
  align-self: center;
  margin-left: 13px;
}

@media (max-width: 855px) {
  .mainNavListItem__right {
    margin-left: 30px;
  }
  .mainNavListItem__avatar {
    margin-right: -20px;
  }
}

@media (max-width: 740px) {
  .mainNavMenu {
    overflow-y: scroll;
  }
  .mainNavListItem__right {
    white-space: nowrap;
    padding-right: 40px;
    margin-left: 0;
  }
  .mainNavListItem__right.mainNavListItem__rightPartial {
    padding-right: 0;
  }
  .mainNavListItem__avatar {
    margin-right: 5px;
  }
}

@media (max-width: 550px) {
  .mainNavMenu {
    height: 60px;
  }
  .mainNavListItem {
    margin-right: 20px;
  }
  .mainNavListItem__avatar {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 420px) {
  .mainNavMenu {
    padding: 0;
    overflow: visible;
  }
  .mainNavListItem__right,
  .mainNavListItem__right.mainNavListItem__rightPartial {
    padding-right: 13px;
    margin: 0;
  }
  [data-reach-listbox-input].mainNavList {
    width: 169px;
    border: none;
    padding: 17px 13px;
    margin-right: 0;
  }
  [data-reach-listbox-popover].mainNavList_popover {
    width: 197px;
    padding-bottom: 10px;

    border-radius: 0 0 10px 10px;
    border: 1px solid #f2f3f8;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

    z-index: 1;
  }
  [data-reach-listbox-option].mainNavListItem {
    padding: 8px 13px;
  }
  .mainNavListItem a {
    color: #303741;
    width: 100%;
    display: block;
  }
  .mainNavListItem.hidden {
    display: none;
  }
  [data-current].mainNavListItem a {
    color: #5E8ADF;
  }
  .mainNavList_popover [data-reach-listbox-option][data-value="default"] {
    border-radius: 0;
    padding: 17px 13px;
  }
  .mainNavList_popover [data-reach-listbox-option][aria-disabled] {
    opacity: 1;
  }
}