.assemblyItem_title {
    margin: 0 30px;
}
.assemblyItem_listItem:nth-child(2n) {
    background: #F2F3F8;
}
.assemblyItem_listItem {
    width: 900px;
    height: 40px;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px;

    display: flex;
    align-items: center;
}
.assemblyItem_listItem .listItem_section .linkCaption {
    display: block;
    font-size: 14px;
    margin-left: 0;
    color: #606D7A;
}
.assemblyItem_download {
    width: 95px;
    height: 24px;

    display: flex;
    cursor: pointer;

    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}
.assemblyItem_listHeader {
    width: 940px;
    margin-left: 20px;
    display: flex;
    position: relative;
}
.assembly-version {
    position: absolute;
    left: 143px;
}
.assembly-date {
    position: absolute;
    left: 245px;
    width: 160px;
}
.assembly-setup {
    position: absolute;
    left: 430px;

    width: 160px;
}
.assembly-description {
    position: absolute;
    left: 602px;

    width: 163px;

    cursor: pointer;
    display: block;
    display: -webkit-box !important;
    max-width: 163px;
    -webkit-line-clamp: 2;
    max-height: 80px;
    -webkit-box-orient: vertical;
    white-space: pre-wrap !important;
}
.assembly-download {
    position: absolute;
    left: 810px;
}
.assembly-fullDescription {
    width: 288px;

    position: absolute;
    left: 600px;
    bottom: 47px;

    color: #FFF;
    padding: 10px;
    display: none;

    background: rgba(48, 55, 65, 0.8);
    border-radius: 4px;
}
.assembly-fullDescription:after {
    display: block;
    content: "";

    width: 20px;
    height: 15px;

    background-size: contain;
    background: url("../assets/corner.svg") no-repeat;

    position: relative;
    top: 25px;
    left: 10px
}
.assemblyFile_loading {
    margin-left: 20px;
    font-family: Open Sans, sans-serif;
}

@media (max-width: 1020px) {
    .assemblyItem_listItem,
    .assemblyItem_listHeader {
        width: calc(100% - 40px);
    }
    .assembly-download {
        left: calc(100% - 70px);
    }
}

@media (max-width: 1000px) {
    .assemblyItem_listHeader {
        margin-bottom: 15px;
    }
    .assembly-setup,
    .assembly-date {
        max-width: 100px;
    }
}

@media (max-width: 960px) {
    .assembly-description {
        left: calc(100% - 250px);
    }
    .assembly-fullDescription {
        left: calc(100% - 248px);
    }
}

@media (max-width: 920px) {
    .assembly-setup {
        left: calc(100% - 350px);
    }
}

@media (max-width: 830px) {
    .assembly-date {
        left: calc(100% - 460px);
    }
}

@media (max-width: 800px) {
    .assembly-version {
        left: calc(100% - 530px);
    }
}

@media (max-width: 768px) {
    .assemblyItem_listItem {
        height: auto;
        padding: 18px 13px;
        width: calc(100% - 30px);
    }
    .assemblyItem_listItem:nth-child(even) {
        background: none;
    }
    .assemblyItem_listItem:nth-child(odd) {
        background: #F2F3F8;
    }
    .assemblyItem_download {
        text-align: right;
        width: initial;
    }
    .assembly-description {
        width: calc(100% - 135px);
        max-width: calc(100% - 140px);
        text-align: right;
        position: initial;
        display: block !important;
    }
}

@media (max-width: 550px) {
    .assemblyItem_listItem {
        width: 100%;
        margin-left: -13px;
    }
}