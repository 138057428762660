.statPage {
    margin: 30px 0;
}
.statPage_header {
    margin-left: 10px;
    margin-bottom: 30px;
}
.statPage_header:not(:first-child) {
    margin-top: 30px;
}
.statPage_form {
    display: flex;
    margin-bottom: 40px;
}
.statPage_chart {
    overflow-x: scroll;
}
.statPage_assemblyItem {
    width: 522px;
}
.statPage_assemblyItem_version {
    position: absolute;
    left: 200px;
}
.statPage_assemblyItem_setup {
    position: absolute;
    left: 330px
}
.section_tableHeader.statPage_assemblyItem_branch,
.section_tableHeader.statPage_assemblyItem_version,
.section_tableHeader.statPage_assemblyItem_setup {
    cursor: pointer;
}

/* стили выпадающих списков*/
[data-reach-listbox-input] {
    border: 1px solid #5E8ADF;
    border-radius: 10px;
    padding: 17px 27px;
    margin-right: 20px;

    cursor: pointer;
    width: 211px;

    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #5E8ADF;

    position: relative;
}
[data-reach-listbox-button] {
    border: none;
    padding: 0;
}
[data-reach-listbox-arrow] {
    position: absolute;
    right: 27px;
    top: 20px;
}
[data-reach-listbox-popover] {
    width: 265px;
    border: 1px solid #5E8ADF;
    border-radius: 10px;
    padding: 0;

    position: absolute;
    top: -1px;
    left: -1px;
}
[data-reach-listbox-option] {
    padding: 8px 27px;

    color: #303741;
}
[data-reach-listbox-option]:hover {
    background: none;
    color: #606d99;
}
[data-reach-listbox-option][data-current] {
    color: #5E8ADF;
}
[data-reach-listbox-option][aria-selected] {
    background: none;
}
[data-reach-listbox-option][data-value="default"] {
    background: #E1EDFF;
    color: #5E8ADF;

    border-radius: 10px 10px 0 0;
    padding: 16px 27px;
    opacity: 1;
}
.listboxOption_arrowIcon {
    transform: scaleY(-1);

    position: absolute;
    top: 23px;
    right: 27px;
}

@media (max-width: 620px) {
    .statPage_assemblyItem {
        width: calc(100% - 40px);
    }
}

@media (max-width: 565px) {
    .statPage_assemblyItem_setup {
        left: calc(100% - 140px)
    }
}

@media (max-width: 510px) {
    .statPage {
        margin: 0;
    }
    .statPage.pageSection {
        padding-top: 0;
    }
    .statPage_assemblyItem_version {
        left: calc(100% - 210px)
    }
}