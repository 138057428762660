.genericItemList.userList {
    flex-direction: row;
    flex-wrap: wrap;
}
.genericItemList_Item.addUser {
    width: 438px;
    margin-bottom: 20px;
    background: #F2F3F8;
}
.editFormField.userField {
    width: 440px;
}

@media (max-width: 980px) {
    .editFormField.userField {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .genericItemList_Item.addUser {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .editFormField.userField {
        margin: 0 10px 20px;
    }
}

@media (max-width: 550px) {
    .editFormField.userField {
        margin: 0 0 20px;
    }
}