.licenseCodes__separator {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 30px;
}

.valueSpan {
  display: inline-block;
  margin-right: 10px;
}

.shifterButton {
  font-family: Manrope, sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;

  border-radius: 4px;
  background-color: #5E8ADF;
  border: 1px solid #5E8ADF;

  margin-left: 5px;
  cursor: pointer;
}
.shifterButton:focus {
  box-shadow: 0 0 1px 1px rgba(94, 137, 223, 0.39);
  outline: none;
}
.shifterButton:active {
  background: #0872D3;
  border: 1px solid #0872D3;
}
.shifterButton[disabled] {
  background-color: #B7C2CD;
  border: 1px solid #B7C2CD;
}

.licenseCodes__row-container {
  height: 32px;
}
.licenseCodes__row-textarea {
  margin-bottom: -3px;
  vertical-align: bottom;
  width: 175px;
}
