.myTextInput {
  font-family: Manrope, sans-serif;
  font-size: 16px;
  color: #303741;
  padding: 15px;
  border-radius: 2px;
  border: 1px solid #DDE0EA;
  margin-bottom: 10px;
}
.myTextInput:focus {
  outline: none;
  border: 1px solid #5E8ADF;
}
.inputError {
  border: 2px solid tomato;
}
